<template>
  <div
    class="emp-filter"
    :key="placeHolder"
  >
    <div
      class="filter-inner-container"
    >
      <v-select
        ref="multiselect"
        v-model="selectedEmployeeType"
        class="filter-select"
        :reduce="employeeTypeOptions => employeeTypeOptions"
        :placeholder="placeHolder"
        :options="finalDocpToShow"
        clear-search-on-select
        @input="searchDocp"
        @update:model-value="employeeTypeFilterChange"
      >
        <template #no-options="{ search, searching }">
          <template v-if="searching && loading">
            searching for <em>{{ search }}</em>.
          </template>
          <template v-else-if="!loading">
            Type to Search..
          </template>
          <template v-else>
            No records found
          </template>
        </template>
        <template #spinner>
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm text-info"
            role="status"
            aria-hidden="true"
          />
        </template>
        <template #option="option">
          <span :class="{'selected_option' : markSelectedOption(option)}">
            <div class="d-flex justify-space-between">
              <div name="label">{{ option.label }}</div>
            </div>
          </span>
        </template>
      </v-select>
    </div>
  </div>
</template>
<script>
import { ref, reactive, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import makeFilter from '../../../composables/makeFilter';
import postData from '../../../composables/postData';
import getFatRow from '../../../composables/fatRowGenerator.js';
import getFolders from '../../../composables/getFolders';
import { sendMessage } from '../../../services/websocket.js';

export default {
  name: 'EmployeeTypeFilter',
  props: {
   currentFolder: {
      type: Object,
      default: () => {}
    },
    onGridReady: {
      type: Function,
      default: () => {}
    },
    filtersData: {
      type: Object,
      default: () => {}
    },
    selectedEmpType: {
      type: Object,
      default: () => {}
    },
    filteredEmp: {
      type: Object,
      default: () => {}
    },
    updateFilterValues: {
      type: Function,
      default: () => {}
    }
  },
  emits: ['update-selected-employee-type'],
  setup(props, context ) {
    const store = useStore();
    const bSettings = store.getters['bSettings'];
    const form = null;
    //EmployeeType Filter start
    const searchTerm = ref('');
    let filterParams = reactive([]);
    const loading = ref(false);
    const employeeTypesList = ref([]);
    const employeeTypeOptions = ref([]);
    const selectedEmployeeType = ref();
    const finalDocpToShow = computed(() => {
      return employeeTypeOptions.value.map((each) => {
        if (each.label == null) {
	  each.label = `${each.name} (${each.code})`
        }
        return each
      })
    })
    let firstLoad = true

    const defaultEmployeeType = computed(() => {
      let rex = new RegExp(/contractual/)
      let result = []
      if (finalDocpToShow.value.length > 0) { 
        result = finalDocpToShow.value.filter( (x) => rex.test(x.label) )
      }
      return result.length > 0  ? result[0].label : null
    })

    const selectedEmployeeTypeId = computed(() => {
      if (selectedEmployeeType.value !== undefined && selectedEmployeeType.value !== null && selectedEmployeeType.value !== 'search employees') {
        return selectedEmployee.value?.id;
      } else {
        return null;
      }
    })
    const selectedEmployeeTypeId2 = computed(() => {
      if (props.filtersData['employee_types'] !== undefined) {
        return props.filtersData?.employee_types?.id;
      } else {
        return null;
      }
    })
    const placeHolder = computed(() => {
      if (defaultEmployeeType.value !== null) {
        return defaultEmployeeType.value;
      } else {
        return 'search employee_types';
      }
    })

    selectedEmployeeType.value = defaultEmployeeType.value;
    const { getAllFoldersList, getCurrentFolder } = getFolders();
    const folderList = getAllFoldersList(bSettings);
    console.log (folderList)
    const currentFolder = getCurrentFolder('employees', folderList);
    console.log (currentFolder)
    const docpOption = currentFolder?.fields?.employee_type;
    console.log (docpOption)
    function markSelectedOption(option) {
      if (selectedEmployeeType.value !== null) {
        if (selectedEmployeeType.value.label === option.label) {
          return true
        }
      } else if (defaultEmployeeType.value === option.name) {
        return true;
      }
    }

    async function getDocpickerList() {
      const folderList = getAllFoldersList(bSettings);
      const currentDocpFolder = ref([]);
      let folderName = null;
      if (!docpOption.source?.folder) {
        folderName = docpOption.name;
      } else {
        folderName = docpOption.source.folder;
      }
      currentDocpFolder.value = getCurrentFolder(folderName, folderList);
      const { listData } = postData();
      employeeTypeOptions.value = []
      let txnParams = reactive({ refRows: true });
      try {
        loading.value = true
        // placeHolder.value = `search for ${currentDocpFolder?.label?.eng}`;
          txnParams.filters = filterParams;
          txnParams.projections = {
            'employee_types': ['id', 'code', 'name']
          }
          const list = await listData(currentDocpFolder.value, txnParams);
          if (list.output.type === 'error') {
            console.log(list.output.message);
          } else {
            if (list.output?.data?.records?.length > 0) {
              const { fatRowGenerator } = getFatRow();
              employeeTypesList.value = fatRowGenerator(list);
              employeeTypeOptions.value = [];
              employeeTypeOptions.value = employeeTypesList.value.map((item) => ({ id: item.id, code: item.code, name: item.name?.eng }));
            }
        }
        filterParams = []
        txnParams = {refRows:true}
        loading.value = false;
        if (firstLoad) { 
          firstLoad = false
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    async function searchDocp(event) {
      try {
        searchTerm.value = event.target.value;
        //TODO:- make filter composable will get a list of fields to filter on if any decide on object or array
        const filterFields = [
          {key:'code'},
          {key:'name'}
        ];
        let isEmployeeTypeFilter = false;
        const folderList = getAllFoldersList(bSettings);
        const employeeTypesFolder = getCurrentFolder('employee_types', folderList);
        const { generateFilterParams } = makeFilter(
          searchTerm.value,
          employeeTypesFolder,
          filterFields
        );
        if (searchTerm.value.length >= 2) {
          filterParams = generateFilterParams();
          if (docpOption.name !== 'employee_types') {
            if (Object.keys(filterParams).length !== 0) {
              loading.value = true;
              await getDocpickerList();
            }
          } else {
            loading.value = true;
            await getDocpickerList();
          }
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    // Functions to handle navigation
    function filterEmpType(type) {
      const selectedValue = {
        label: `${type.name})`,
        id: typ.id
      }
      selectedEmployeeType.value = selectedValue;
      context.emit('update-selected-employee-type', selectedValue);
      // employeeTypeFilterChange();
    }

    function employeeTypeFilterChange() {
      if (selectedEmployeeType.value !== null) {
        props.updateFilterValues({type:'employee_type', value:selectedEmployeeType.value})
      } else {
        props.updateFilterValues({type:'employee_type', value:null})
      }
      context.emit('update-selected-employee-type', selectedEmployeeType.value.name);
      props.onGridReady();
    }


    onMounted(function ()  {
      getDocpickerList()
    })

    //Employee FilterEnd


    return {
      searchTerm,
      placeHolder,
      searchDocp,
      loading,
      selectedEmployeeType,
      finalDocpToShow,
      markSelectedOption,
      employeeTypeOptions,
      employeeTypeFilterChange
    }
  }
}
</script>

<style>
 @media print{
     .arrow-button {
         display:none !important;
     }
 }
  .vs__actions {
    justify-content: flex-end;
  }

  .vs--searchable .vs__dropdown-toggle {
    height: 30px;
  }

  .arrow-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    margin: 0 5px;
  }

  .arrow-button:disabled {
    cursor: not-allowed;
    color: #ccc;
  }

  /* Highlighting the selected employee in the summary list */
  .selected-employee {
    background-color: #e6f7ff;
    font-weight: bold;
  }


  .emp-filter {
    width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    color: #292929;
  }

  .emp-filter span {
    font-size:15px !important;
    margin: 0 !important;
  }

  .filter-inner-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 2px;
    padding: 2px;
  }

  .filter-inner-container span {
    font-size:14px;
    margin: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .filter-select {
    font-size: 12px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  .selected_option {
    font-weight: 700;
    color:green;
  }
  .selected_option :hover {
    color:white;
  }

  .employee-filter-container {
    color: #292929;
    max-height: 400px; /* Set the maximum height of the table container */
    overflow-y: auto;  /* Enable vertical scrolling */
    border: 1px solid black; /* Add border around the scrollable area */
  }

  .attstatus-table {
    width: 100%;
    border-collapse: collapse;
  }

  .attstatus-table th,
  .attstatus-table td {
    border: 1px solid black; /* Add border to table headers and cells */
    padding: 8px;
    text-align: left;
  }

  .attstatus-table th {
    background-color: #f2f2f2; /* Optional: Add background color to header */
    font-weight: bold;
    position: sticky; /* Keep the header fixed */
    top: 0; /* Fix the header to the top */
    z-index: 1; /* Ensure the header stays above the table rows */
  }

  .summary_list {
    cursor: pointer;
  }

  .summary_list:hover {
    color: #83ACC8;
  }

</style>
