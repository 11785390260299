//in ths composable keeping all urls in one place

import { setSessionKey, getSessionKey } from '../services/sessionStore.js';
import store from '../../store/';
import { v4 as uuidv4 } from 'uuid';

export function usePaths() {
  const webProtocol = window.location.protocol
  const socketProtocol = webProtocol === 'https:' ? 'wss:' : 'ws:';
  const server_port = socketProtocol === 'ws:' ? 8190 : 8191;

  store.commit('serverPortMutation', server_port);

  if (getSessionKey() == null) {
    setSessionKey(uuidv4());
  }

  const baseWsUrl = `${socketProtocol}//${window.location.hostname}:${server_port}`;
  const baseHttpUrl = `${webProtocol}//${window.location.hostname}:${server_port}`;
  //websocket url
  const serverWsAddress = `${baseWsUrl}/?apikey=${getSessionKey()}`;
  //file upload axios url
  const axiosFileUpload = `${baseHttpUrl}/applyFiles`;
  //files being served from path url
  const fileServePath = `${webProtocol}//${window.location.hostname}:${server_port}`
  //base path for files to upload url
  const fileStoreLocationPath = '/home/wfm/wfm-servers/part1/file_uploads'

  return {
    baseWsUrl,
    baseHttpUrl,
    serverWsAddress,
    axiosFileUpload,
    fileServePath,
    fileStoreLocationPath
  };
}
