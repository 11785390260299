<template>
  <div
    class="emp-filter"
  >
    <div
      class="filter-inner-container"
    >
      <button
        v-if="isRO || isApprovalManager"
        class="arrow-button"
        :disabled="isFirstEmployee"
        @click="previousEmployee"
      >
        <svg
          data-v-b800c10e=""
          class="ov-icon mt-1"
          aria-hidden="true"
          width="28.8"
          height="28.8"
          viewBox="-1.6 -1.6 19.2 19.2"
          fill="#2f688d"
          style="font-size: 1.8em;"
        >
          <path
            d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 00-1.659-.753l-5.48 4.796a1 1 0 000 1.506z"
            fill="#2f688d"
          />
        </svg>
      </button>
      <v-select
        ref="multiselect"
        v-model="selectedEmployee"
        class="filter-select"
        :reduce="employeesOptions => employeesOptions"
        :placeholder="placeHolder"
        :options="finalDocpToShow"
        clear-search-on-select
        @input="searchDocp"
        @update:model-value="employeesFilterChange"
      >
        <template #no-options="{ search, searching }">
          <template v-if="searching && loading">
            searching for <em>{{ search }}</em>.
          </template>
          <template v-else-if="!loading">
            Type to Search..
          </template>
          <template v-else>
            No records found
          </template>
        </template>
        <template #spinner>
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm text-info"
            role="status"
            aria-hidden="true"
          />
        </template>
        <template #option="option">
          <span :class="{'selected_option' : markSelectedOption(option)}">
            <div class="d-flex justify-space-between">
              <div name="label">{{ option.label }}</div>
            </div>
          </span>
        </template>
      </v-select>
      <button
        v-if="isRO || isApprovalManager"
        class="arrow-button"
        :disabled="isLastEmployee"
        @click="nextEmployee"
      >
        <svg
          data-v-b800c10e=""
          class="ov-icon mt-1"
          aria-hidden="true"
          width="28.8"
          height="28.8"
          viewBox="-1.6 -1.6 19.2 19.2"
          fill="#2f688d"
          style="font-size: 1.8em;"
        >
          <path
            d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 011.659-.753l5.48 4.796a1 1 0 010 1.506z"
            fill="#2f688d"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import { ref, reactive, computed, watch } from 'vue';
import { useStore } from 'vuex';
import makeFilter from '../../../composables/makeFilter';
import postData from '../../../composables/postData';
import getFatRow from '../../../composables/fatRowGenerator.js';
import getFolders from '../../../composables/getFolders';
import { sendMessage } from '../../../services/websocket.js';

export default {
  name: 'RegisterSummary',
  props: {
    currentFolder: {
      type: Object,
      default: () => {}
    },
    onGridReady: {
      type: Function,
      default: () => {}
    },
    filtersData: {
      type: Object,
      default: () => {}
    },
    selectedEmp: {
      type: Object,
      default: () => {}
    },
    filteredEmp: {
      type: Object,
      default: () => {}
    },
    updateFilterValues: {
      type: Function,
      default: () => {}
    },
  },
  emits: ['update-selected-employee'],
  setup(props, { emit }) {
    const store = useStore();
    const bSettings = store.getters['bSettings'];
    let isApprovalManager = false;
    let isRO = false;
    if (bSettings?.env?.code === 'admin') {
      isApprovalManager = true;
    } else if (bSettings?.env?.isAO) {
      isApprovalManager = true;
    }
    if (bSettings?.env?.isRO) {
      isRO = true;
    }
    const form = null;
    //Employees Filter start
    const searchTerm = ref('');
    let filterParams = reactive([]);
    const loading = ref(false);
    const employeesList = ref([]);
    const employeesOptions = ref([]);
    const selectedEmployee = ref();
    const finalDocpToShow = computed(() => {
      return employeesOptions.value.map((each) => {
        if (each.label == null) {
	  each.label = `${each.first_name || each?.name?.eng} ${each?.last_name !== undefined ? each.last_name : ''}  (${each.code}) - ${each.employee_type}`
        }
        return each
      })
    })
    const defaultEmployee = computed(() => {
      if (props.filtersData['employees'] !== undefined) {
	      return `${props.filtersData?.employees?.persons_id[0]?.first_name?.eng} ${props.filtersData?.employees?.persons_id[0]?.last_name?.eng}(${props.filtersData?.employees?.code}) - ${props.filtersData.employees.employee_type != null ? props.filtersData.employees.employee_type[0].name.eng : 'NA'}`;
      } else {
        return null;
      }
    })
    const selectedEmployeeId = computed(() => {
      if (selectedEmployee.value !== undefined && selectedEmployee.value !== null && selectedEmployee.value !== 'search employees') {
        return selectedEmployee.value?.id;
      } else {
        return null;
      }
    })
    const selectedEmployeeId2 = computed(() => {
      if (props.filtersData['employees'] !== undefined) {
        return props.filtersData?.employees?.id;
      } else {
        return null;
      }
    })
    const placeHolder = computed(() => {
      if (defaultEmployee.value !== null) {
        return defaultEmployee.value;
      } else {
        return 'search employees';
      }
    })
    selectedEmployee.value = defaultEmployee.value;
    const { getAllFoldersList, getCurrentFolder } = getFolders();
    const folderList = getAllFoldersList(bSettings);
    const currentFolder = getCurrentFolder('attendance_edits', folderList);
    const docpOption = currentFolder?.fields?.employees_id;
    function markSelectedOption(option) {
      if (selectedEmployee.value !== null) {
        if (selectedEmployee.value.label === option.label) {
          return true
        }
      } else if (defaultEmployee.value === option.name) {
        return true;
      }
    }

    async function getDocpickerList() {
      const folderList = getAllFoldersList(bSettings);
      const currentDocpFolder = ref([]);
      let folderName = null;
      if (!docpOption.source?.folder) {
        folderName = docpOption.name;
      } else {
        folderName = docpOption.source.folder;
      }
      currentDocpFolder.value = getCurrentFolder(folderName, folderList);
      const { listData } = postData();
      employeesOptions.value = []
      let txnParams = reactive({ refRows: true });
      try {
        // placeHolder.value = `search for ${currentDocpFolder?.label?.eng}`;
        if (Object.keys(filterParams).length !== 0) {
          txnParams.filters = filterParams;
          txnParams.projections = {
            'employees': ['id', 'code', 'persons_id', 'designation', 'departments', 'employee_type'],
            'employees.designation': ['id'],
            'employees.departments': ['id'],
            'employees.persons_id': ['id', 'first_name', 'last_name', 'middle_name'],
            'employees.employee_type': ['id', 'code', 'name']
          }
          const list = await listData(currentDocpFolder.value, txnParams);
          if (list.output.type === 'error') {
            console.log(list.output.message);
          } else {
            if (list.output?.data?.records?.length > 0) {
              const { fatRowGenerator } = getFatRow();
              employeesList.value = fatRowGenerator(list);
              employeesOptions.value = [];
              employeesOptions.value = employeesList.value.map((item) => ({ first_name: item['persons_id'][0]?.['first_name']?.['eng'], last_name: item['persons_id'][0]?.['last_name']?.['eng'], code: item.code, id: item.id, employee_type: item['employee_type'][0]?.['name']['eng'] }));
            }
          }
          loading.value = false;
        }
        filterParams = []
        txnParams = {refRows:true}
      }
      catch (err) {
        console.log(err)
      }
    }

    async function searchDocp(event) {
      try {
        searchTerm.value = event.target.value;
        //TODO:- make filter composable will get a list of fields to filter on if any decide on object or array
        const filterFields = [{key:'code'},
          {key:'first_name'},
          {key:'last_name'},
          {key:'middle_name'},
          {key:'name'}
        ];
        let isEmployeeFilter = false;
        if (form !== null && form !== undefined) {
          for (let i = 0; i < form.length; i++) {
            if (form[i].name === 'employee' && form[i].visible_expr) {
              isEmployeeFilter = true;
              filterFields.push({key:'employee_status', value:'approved', operator:'=', joinop:'and'})
            }
          }
        }
        if (!isEmployeeFilter) {
          filterFields.push({key:'employee_status', value:'approved', operator:'=', joinop:'and'})
          filterFields.push({key:'employee_type', value:'user', operator:'!=', joinop:'and'})
          filterFields.push({key:'employee_type', value:'regular', operator:'!=', joinop:'and'})
        }
        const folderList = getAllFoldersList(bSettings);
        const employeesFolder = getCurrentFolder('employees', folderList);
        const { generateFilterParams } = makeFilter(
          searchTerm.value,
          employeesFolder,
          filterFields
        );
        if (searchTerm.value.length >= 2) {
          filterParams = generateFilterParams();
          if (docpOption.name !== 'employees') {
            if (Object.keys(filterParams).length !== 0) {
              loading.value = true;
              getDocpickerList();
            }
          } else {
            loading.value = true;
            getDocpickerList();
          }
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    // Functions to handle navigation
    function filterEmp(emp) {
      const selectedValue = {
        label: `${emp.name} (${emp.empCode}) - ${emp.empType}`,
        id: emp.id,
        type: emp.empType
      }
      selectedEmployee.value = selectedValue;
      emit('update-selected-employee', selectedValue);
      // employeesFilterChange();
    }
    const filteredAttendanceSummary = computed(() => {
      return props.filteredEmp;
    });
    const selectedEmployeeIndex = computed(() => {
      if (!selectedEmployee.value) return -1;
      return filteredAttendanceSummary.value.findIndex((emp) => emp.id === selectedEmployee.value.id);
    });

    const isFirstEmployee = computed(() => selectedEmployeeIndex.value === 0);
    const isLastEmployee = computed(() => selectedEmployeeIndex.value === filteredAttendanceSummary.value.length - 1);

    const previousEmployee = () => {
      if (selectedEmployeeIndex.value > 0) {
        filterEmp(filteredAttendanceSummary.value[selectedEmployeeIndex.value - 1]);
      }
    };

    // Method to select the next employee in the attendance summary
    const nextEmployee = () => {
      if (selectedEmployeeIndex.value < filteredAttendanceSummary.value.length - 1) {
        filterEmp(filteredAttendanceSummary.value[selectedEmployeeIndex.value + 1]);
      }
    };

    function employeesFilterChange() {
      if (selectedEmployee.value !== null) {
        props.updateFilterValues({type:'employee', value:selectedEmployee.value})
      } else {
        props.updateFilterValues({type:'employee', value:null})
      }
      props.onGridReady();
    }
    async function getLeavesQuota(empId) {
      try {
        const currentEmp = empId
        let leavesQuota = [];
        //provide the empId gets the leave quota
        //TODOsend the timestamp too
        const folderList = getAllFoldersList(bSettings);
        const leavesFolder = getCurrentFolder('leaves', folderList);
        const txnToGetLeaveQuota = leavesFolder?.txns?.txn_show_leaves_quota
        const txnParams = { 'refRows': true, 'employees_id': currentEmp }
        txnToGetLeaveQuota.params = txnParams
        const result = await sendMessage(txnToGetLeaveQuota)
        if (result?.output?.data?.records?.[0]) {
          leavesQuota = JSON.parse(result.output.data?.records?.[0])
          store.commit('commonModule/leavesQuotaMutations', leavesQuota)
        }
      } catch (error) {
        console.log(error)
      }
    }

    watch(selectedEmployeeId, (newValue) => {
      try {
        if (newValue !== null) {
          getLeavesQuota(newValue)
        }
      } catch (error) {
        console.log(error)
      }
    });

    watch(selectedEmployeeId2, (newValue) => {
      try {
        if (newValue !== null) {
          getLeavesQuota(newValue)
        }
      } catch (error) {
        console.log(error)
      }
    });


    watch(() => props.selectedEmp, (newVal) => {
      if (newVal) {
        // Assuming newVal has the same structure as selectedEmployee
        selectedEmployee.value = {
          label: `${newVal.label}`, // or any other structure
          id: newVal.id,
        };
        employeesFilterChange()
      } else {
        selectedEmployee.value = null; // Reset if newVal is null or undefined
      }
    }, { immediate: true });
    //Employee FilterEnd


    return {
      isApprovalManager,
      isRO,
      //EmployeesFilter
      searchTerm,
      placeHolder,
      searchDocp,
      loading,
      selectedEmployee,
      finalDocpToShow,
      markSelectedOption,
      employeesOptions,
      employeesFilterChange,
      isFirstEmployee,
      isLastEmployee,
      previousEmployee,
      nextEmployee,


    }
  }
}
</script>

<style>
 @media print{
     .arrow-button {
         display:none !important;
     }
 }
  .vs__actions {
    justify-content: flex-end;
  }

  .vs--searchable .vs__dropdown-toggle {
    height: 30px;
  }

  .arrow-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    margin: 0 5px;
  }

  .arrow-button:disabled {
    cursor: not-allowed;
    color: #ccc;
  }

  /* Highlighting the selected employee in the summary list */
  .selected-employee {
    background-color: #e6f7ff;
    font-weight: bold;
  }


  .emp-filter {
    width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    color: #292929;
  }

  .emp-filter span {
    font-size:15px !important;
    margin: 0 !important;
  }

  .filter-inner-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 2px;
    padding: 2px;
  }

  .filter-inner-container span {
    font-size:14px;
    margin: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .filter-select {
    font-size: 12px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  .selected_option {
    font-weight: 700;
    color:green;
  }
  .selected_option :hover {
    color:white;
  }

  .employee-filter-container {
    color: #292929;
    max-height: 400px; /* Set the maximum height of the table container */
    overflow-y: auto;  /* Enable vertical scrolling */
    border: 1px solid black; /* Add border around the scrollable area */
  }

  .attstatus-table {
    width: 100%;
    border-collapse: collapse;
  }

  .attstatus-table th,
  .attstatus-table td {
    border: 1px solid black; /* Add border to table headers and cells */
    padding: 8px;
    text-align: left;
  }

  .attstatus-table th {
    background-color: #f2f2f2; /* Optional: Add background color to header */
    font-weight: bold;
    position: sticky; /* Keep the header fixed */
    top: 0; /* Fix the header to the top */
    z-index: 1; /* Ensure the header stays above the table rows */
  }

  .summary_list {
    cursor: pointer;
  }

  .summary_list:hover {
    color: #83ACC8;
  }

</style>
